'use client'

import { showErrorToast, showToast } from '@/components/SnackBar/BaseSnackBar'
import { AppPath } from '@/routes/routes'
import { isEmpty } from 'lodash'
import {
  type RpcStatus,
  type ResponseError,
  type V1AppErrorDetail,
  instanceOfV1AppErrorDetail,
  type ProtobufAny
} from 'my-recruiter-app-ts-if'
import { useErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'

export const errorMessageUnexpectedError =
  '想定外のエラーが発生しました。時間を空けて再度お試しいただき、解決しない場合は担当までご連絡ください。'

export const isInstanceOfResponseError = (obj: any): obj is ResponseError => {
  // return obj instanceof ResponseError;
  return 'name' in obj && obj.name === 'ResponseError'
}

export const parseRpcStatus = async (
  responseError: ResponseError
): Promise<RpcStatus> => {
  const responseText = await responseError.response.text()
  const rpcStatus: RpcStatus = JSON.parse(responseText)
  return rpcStatus
}

export const parseAppErrorDetail = (
  rawAppErrorDetails: ProtobufAny[] | undefined
): V1AppErrorDetail | undefined => {
  if (rawAppErrorDetails === undefined || rawAppErrorDetails.length < 1) {
    return undefined
  }

  if (!instanceOfV1AppErrorDetail(rawAppErrorDetails[0])) {
    return undefined
  }

  return rawAppErrorDetails[0] as V1AppErrorDetail
}

// Function to handle errors asynchronously
export const handleQueryError = async (err: unknown): Promise<void> => {
  if (isInstanceOfResponseError(err)) {
    const rpcStatus = await parseRpcStatus(err)
    if (rpcStatus.code === GRPC_CODE.UNAUTHENTICATED) {
      window.location.href = AppPath.SignIn
    } else {
      !isEmpty(rpcStatus.message) && showErrorToast(String(rpcStatus.message))
    }
  } else {
    console.error('An unexpected error occurred:', err)
  }
}

type HandleQueryErrorFunction = () => (err: unknown) => Promise<void>

export const useHandleQueryError: HandleQueryErrorFunction = () => {
  const navigate = useNavigate()
  const { showBoundary } = useErrorBoundary()

  return async (err: unknown): Promise<void> => {
    if (isInstanceOfResponseError(err)) {
      const rpcStatus = await parseRpcStatus(err)

      if (rpcStatus.code === GRPC_CODE.INTERNAL) {
        showBoundary(Error(rpcStatus.message))
      } else if (rpcStatus.code === GRPC_CODE.UNAUTHENTICATED) {
        navigate(AppPath.SignIn, { replace: true })
      } else if (rpcStatus.code === GRPC_CODE.ALREADY_EXISTS) {
        !isEmpty(rpcStatus.message) && showToast(String(rpcStatus.message))
      } else {
        !isEmpty(rpcStatus.message) && showErrorToast(String(rpcStatus.message))
      }
    } else {
      console.error('An unexpected error occurred:', err)
    }
  }
}

export const GRPC_CODE = {
  OK: 0, // status code 200
  CANCELLED: 1, // status code 499
  UNKNOWN: 2, // status code 500
  INVALID_ARGUMENT: 3, // status code 400
  DEADLINE_EXCEEDED: 4, // status code 504
  NOT_FOUND: 5, // status code 404
  ALREADY_EXISTS: 6, // status code 409
  PERMISSION_DENIED: 7, // status code 403
  RESOURCE_EXHAUSTED: 8, // status code 429
  FAILED_PRECONDITION: 9, // status code 400
  ABORTED: 10, // status code 409
  OUT_OF_RANGE: 11, // status code 400
  UNIMPLEMENTED: 12, // status code 501
  INTERNAL: 13, // status code 500
  UNAVAILABLE: 14, // status code 503
  DATA_LOSS: 15, // status code 500
  UNAUTHENTICATED: 16 // status code 401
}
